
<template>
  <v-card>
    <v-card-title>
      <v-select
        v-model="selectedYear"
        :items="selectYears"
        item-text="text"
        item-value="value"
        label="Year"
        single-line>
      </v-select>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" :prepend-icon="$vuetify.icons.values.mdiMagnify" label="Search" single-line></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="hunters" :loading="needLoad" :search="search" class="elevation-1" :footer-props="{'items-per-page-options': [10,25,100]}">
      <v-progress-linear slot="progress" color="green lighten-3" indeterminate></v-progress-linear>
      <template v-slot:[`item.activeCallsign`]="{ item }">
        <router-link :to="`/profile/${item.activeCallsign}`">{{ item.activeCallsign }}</router-link>
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning">
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
    <v-card-subtitle>Results limited to hunters with a minimum of 10 hunted parks.</v-card-subtitle>
  </v-card>
</template>


<script>
import axios from 'axios'
import dayjs from 'dayjs'

export default {
    mounted () {
      this.loadHunters();

      this.selectYears = [
          { text: 'All-Time', value: 'all' }
        ];

      var currentYear = dayjs().utc().year();
      for (var year = currentYear; year >= 2016; year--)
        this.selectYears.push({ text: year, value: year });
    },
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Callsign',
            align: 'left',
            sortable: false,
            value: 'activeCallsign'
          },
          { value: 'numParks', text: 'Parks', align: 'right' },
          { value: 'numQSOs', text: 'Total QSOs', align: 'right' },
          { value: 'qsosCW', text: 'Total CW', align: 'right' },
          { value: 'qsosDATA', text: 'Total Data', align: 'right' },
          { value: 'qsosPHONE', text: 'Total Phone', align: 'right' }
        ],
        selectYears: [
          { text: 'All-Time', value: 'all' }
        ],
        selectedYear: 'all',
        hunters: [],
        needLoad: true
      }
    },
    methods: {
      loadHunters() {
        if (!this.needLoad)
          return;

        this.hunters = [];

        var year = '';
        if (this.selectedYear != 'all')
          year = `?year=${this.selectedYear}`;

        axios
          .get(`https://${process.env.VUE_APP_API_URL}/hunter/all${year}`)
          .then(response => response.data)
          .then(json => {
            if (json)
              this.hunters = json;

            this.needLoad = false;
          });
      }
    },
    watch: {
      selectedYear() {
        this.needLoad = true;
        this.loadHunters();
      }
    }
}
</script>
